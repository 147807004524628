import {
  canonical,
  capitalizeFirstLetter,
  checkDelayedResult,
  isVariableDefinedNotNull,
  readCookie,
  toParamsQueryString,
} from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';
import stimulus, { FUSE_TOOLTIP_CONTROLLER } from 'plugins/stimulus/index';

import OneOffMicrotaskDispatch from './one_off_microtask_dispatch';

function nextSortDirection({ current, first }) {
  if (!current) return first;

  const next = current === 'asc' ? 'desc' : 'asc';
  if (next === first) return '';

  return next;
}

export default class extends ApplicationController {
  static get targets() {
    return [
      'spinnerTemplate',
      'errorTemplate',
      'header',
      'footer',
      'empty',
      'content',
      'item',
      'toggleAllCheckbox',
      'sort',
    ];
  }

  static get values() {
    return {
      autoReload: {
        type: Number,
        default: 0,
      },
      delayedResult: {
        type: Boolean,
        default: false,
      },
      url: {
        type: String,
        default: null,
      },
      hasFilter: {
        type: Boolean,
        default: false,
      },
      sortBy: {
        type: String,
        default: '',
      },
      sortDirection: {
        type: String,
        default: '',
      },
      settingsCookieName: {
        type: String,
        default: '',
      },
    };
  }

  static get classes() {
    return ['sortAsc', 'sortDesc', 'sortColor'];
  }

  initialize() {
    this.fetchRequest = null;

    this.autoReloadTimeout = null;

    this.toggleEmptyTargetDispatch = new OneOffMicrotaskDispatch();
    this.dispatchItemsChangeDispatch = new OneOffMicrotaskDispatch();

    this.filterData = null;
    this.clientSideFilterData = null;

    this.lastSelectedItemIndex = null;
  }

  connect() {
    this.showSpinner();

    if (this.isTurboPreview) return;

    this.#loadSortState();

    if (!this.hasFilterValue) {
      this.load();
    }
  }

  disconnect() {
    this.#stopAutoReload();

    this.toggleEmptyTargetDispatch = new OneOffMicrotaskDispatch();
    this.dispatchItemsChangeDispatch = new OneOffMicrotaskDispatch();

    this.filterData = null;
    this.clientSideFilterData = null;

    if (this.fetchRequest) {
      this.fetchRequest.abortController.abort();
      this.fetchRequest = null;
    }
  }

  itemTargetConnected() {
    this.#toggleEmptyTarget();
    this.#dispatchItemsChange({ clientSideChange: false });
  }

  itemTargetDisconnected() {
    this.#toggleEmptyTarget();
    this.#dispatchItemsChange({ clientSideChange: false });
  }

  #dispatchItemsChange({ clientSideChange }) {
    this.dispatchItemsChangeDispatch.call(() => {
      this.dispatch('itemsChange', {
        detail: {
          clientSideChange,
          itemsValues: this.#itemsValues,
        },
      });
    });
  }

  get #itemsValues() {
    const values = [];

    for (const item of this.itemTargets) {
      if (!item.hidden) {
        const itemController = this.findControllerOnElement(item);
        if (itemController) values.push(itemController.itemValue);
      }
    }

    return values;
  }

  #toggleEmptyTarget() {
    this.toggleEmptyTargetDispatch.call(() => {
      const isEmpty = !this.hasItemTarget;

      if (this.hasEmptyTarget) {
        this.emptyTarget.hidden = !isEmpty;
      }

      if (this.hasHeaderTarget) {
        this.headerTarget.hidden = isEmpty;
      }

      if (this.hasFooterTarget) {
        this.footerTarget.hidden = isEmpty;
      }
    });
  }

  get cookieName() {
    return this.identifier;
  }

  /// List loading

  reload(event, secretReload = false) {
    if (event) {
      event.preventDefault();
    }

    if (this.hasFilterValue) {
      this.load({ filter: this.filterData }, secretReload);
    } else {
      this.load({}, secretReload);
    }
  }

  updateSettings({ detail } = {}) {
    if (detail.reload) {
      this.load();
    }

    if (!this.contentTarget.firstElementChild) return;

    this.contentTarget.firstElementChild.dataset.visibleColumns = detail.columns.join(' ');
    this.contentTarget.firstElementChild.dataset.enabledOptions = detail.options.join(' ');
  }

  load({ filter: filterData = this.filterData, rerenderFilter = false } = {}, secretLoad = false) {
    if (this.fetchRequest) {
      this.fetchRequest.abortController.abort();
      this.fetchRequest = null;
    }

    this.filterData = filterData;

    if (!secretLoad) {
      this.clearSelection();
      this.showSpinner();
    }

    const params = {};

    if (this.hasFilterValue) {
      params.rerender_filter = rerenderFilter;

      if (isVariableDefinedNotNull(this.filterData)) {
        params.filter = {};

        for (const [key, value] of Object.entries(this.filterData)) {
          if (value === 'NONE' || value === '') {
            continue;
          }

          let normalizedKey = key;

          if (Array.isArray(value) && normalizedKey.endsWith('[]')) {
            normalizedKey = normalizedKey.slice(0, -2);
          }

          params.filter[normalizedKey] = value;
        }
      }
    }

    for (const key of Object.keys(this.additionalLoadParams)) {
      params[key] = this.additionalLoadParams[key];
    }

    const query = toParamsQueryString(params);
    const fetchAbortController = new AbortController();

    this.dispatch('loading', { detail: { secretLoad, params } });

    const handleStream = (html) => {
      window.Turbo.renderStreamMessage(html);
      queueMicrotask(() => this.dispatch('loaded', { detail: { secretLoad, params } }));
    };

    let url = this.urlValue;

    if (query && query !== '') {
      if (url.includes('?')) {
        url += `&${query}`;
      } else {
        url += `?${query}`;
      }
    }

    this.fetchRequest = fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: this.delayedResultValue ? 'text/javascript' : 'text/vnd.turbo-stream.html',
      },
      signal: fetchAbortController.signal,
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 400) {
          throw new Error(response.statusText);
        }

        if (this.delayedResultValue) {
          response.json().then((result) => {
            if (!result.delayed_result_id) {
              throw new Error('Loading list from from delayed result failed: No delayed result ID.');
            }

            checkDelayedResult(
              result.delayed_result_id,
              (delayedResult) => {
                if (delayedResult.success) {
                  handleStream(delayedResult.content_html);
                } else if (result.errors) {
                  throw new Error('Loading list from from delayed result failed:', result.errors);
                }
              },
              500,
            );
          });

          return null;
        }

        return response.text();
      })
      .then((html) => {
        if (html) {
          handleStream(html);
        }

        this.fetchRequest = null;
      })
      .catch((error) => {
        if (fetchAbortController.signal.aborted) {
          return;
        }

        console.warn(error);
        this.renderListError();
        this.fetchRequest = null;
      });

    this.fetchRequest.abortController = fetchAbortController;

    this.#startAutoReload();
  }

  #startAutoReload() {
    if (this.autoReloadValue > 0) {
      const scheduleAutoReload = () => {
        this.autoReloadTimeout = setTimeout(() => {
          this.autoReloadTimeout = null;
          this.reload(null, true);

          scheduleAutoReload();
        }, this.autoReloadValue);
      };

      scheduleAutoReload();
    }
  }

  #stopAutoReload() {
    if (this.autoReloadTimeout) {
      clearTimeout(this.autoReloadTimeout);
      this.autoReloadTimeout = null;
    }
  }

  #setContent(html) {
    this.contentTarget.innerHTML = html;
  }

  renderListError() {
    this.#setContent(this.errorTemplateTarget.innerHTML);
  }

  showSpinner() {
    this.#setContent(this.spinnerTemplateTarget.innerHTML);
  }

  get additionalLoadParams() {
    const params = {};

    if (this.sortByValue) {
      params.sort = {
        by: this.sortByValue,
        direction: this.sortDirectionValue,
      };
    }

    if (this.#settingsData) {
      params.columns = this.#settingsData.columns.length > 0 ? this.#settingsData.columns : [''];
      params.options = this.#settingsData.options.length > 0 ? this.#settingsData.options : [''];
    }

    return params;
  }

  /// Filtering

  filter({ detail }) {
    this.load(detail, false);
  }

  filterClientSide(event) {
    if (event) {
      this.clientSideFilterData = event.detail.filter;
    }

    if (!this.clientSideFilterData) return;

    for (const item of this.itemTargets) {
      if (item.dataset.clientSideFiltered !== 'true') {
        continue;
      }

      let json = JSON.parse(item.dataset.filterValues);
      if (!Array.isArray(json)) {
        json = [json];
      }

      let found = false;
      let noFilter = true;

      for (const filterValue of Object.values(this.clientSideFilterData)) {
        const canonicalFilterValue = canonical(filterValue);
        if (!canonicalFilterValue || canonicalFilterValue === '') {
          continue;
        }

        noFilter = false;

        const someFound = json.some((v) => {
          if (!isVariableDefinedNotNull(v)) return false;
          if (typeof v === 'string') return canonical(v).includes(canonicalFilterValue);
          if (v.toString && v.toString() === canonicalFilterValue) return true;

          return false;
        });

        if (someFound) {
          found = true;
          break;
        }
      }

      const visible = noFilter || found;

      item.hidden = !visible;
    }

    if (!this.fetchRequest) {
      this.#dispatchItemsChange({ clientSideChange: true });
    }
  }

  // Item selection

  clearSelection() {
    this.#toggleAllItemsSelection(false);
  }

  toggleAllItemsSelection() {
    this.#toggleAllItemsSelection(this.#selectedItemsValues.length === 0);
  }

  #toggleAllItemsSelection(selected) {
    if (this.hasToggleAllCheckboxTarget) {
      this.toggleAllCheckboxTarget.checked = selected;
    }

    for (const item of this.itemTargets) {
      if (selected && item.hidden) continue;

      const itemController = this.findControllerOnElement(item);
      itemController.toggleSelected(selected);
    }

    this.#dispatchSelectionChange();
  }

  updateSelection({ detail: { hasShiftKey, shiftKey, item: selectedItem, selected } }) {
    if (hasShiftKey) {
      const items = Array.from(selectedItem.parentNode.children);
      const selectedItemIndex = items.indexOf(selectedItem);

      if (shiftKey && this.lastSelectedItemIndex !== null) {
        const fromIndex = Math.min(selectedItemIndex, this.lastSelectedItemIndex);
        const toIndex = Math.max(selectedItemIndex, this.lastSelectedItemIndex);

        for (const item of items.slice(fromIndex, toIndex + 1)) {
          const itemController = this.findControllerOnElement(item);
          itemController.toggleSelected(selected);
        }

        this.#dispatchSelectionChange();
      }

      this.lastSelectedItemIndex = selectedItemIndex;
    }

    if (!this.hasToggleAllCheckboxTarget) {
      this.#dispatchSelectionChange();

      return;
    }

    const selectedItemsCount = this.#selectedItemsValues.length;

    if (!selectedItemsCount) {
      this.toggleAllCheckboxTarget.checked = false;
      this.toggleAllCheckboxTarget.indeterminate = false;
    } else if (selectedItemsCount === this.itemTargets.length) {
      this.toggleAllCheckboxTarget.checked = true;
      this.toggleAllCheckboxTarget.indeterminate = false;
    } else {
      this.toggleAllCheckboxTarget.checked = false;
      this.toggleAllCheckboxTarget.indeterminate = true;
    }

    this.#dispatchSelectionChange();
  }

  get #selectedItemsValues() {
    const values = [];

    for (const item of this.itemTargets) {
      const itemController = this.findControllerOnElement(item);
      if (itemController.selected) {
        values.push(itemController.selectedValue);
      }
    }

    return values;
  }

  #dispatchSelectionChange() {
    this.dispatch('selectionChange', { detail: { selectedItemValues: this.#selectedItemsValues } });
  }

  /// Sorting

  sort(event) {
    this.#updateSortFromTarget(event.currentTarget);
    this.#persistSortState();

    setTimeout(this.load.bind(this), 0);
  }

  disableSorting() {
    for (const target of this.sortTargets) {
      target.disabled = true;
      stimulus.setControllerDataValue(target, { [FUSE_TOOLTIP_CONTROLLER]: { disabled: false } });
    }
  }

  enableSorting() {
    for (const target of this.sortTargets) {
      target.disabled = false;
      stimulus.setControllerDataValue(target, { [FUSE_TOOLTIP_CONTROLLER]: { disabled: true } });
    }
  }

  sortByValueChanged(newValue, oldValue) {
    if (newValue === oldValue) return;

    if (oldValue) {
      const oldSortTarget = this.sortTargets.find((target) => this.#sortNameFromTarget(target) === oldValue);
      oldSortTarget?.classList?.remove(...this.sortColorClasses, ...this.sortAscClasses, ...this.sortDescClasses);
    }

    if (newValue) {
      const newSortTarget = this.sortTargets.find((target) => this.#sortNameFromTarget(target) === newValue);
      newSortTarget?.classList?.add(...this.sortColorClasses);
    }
  }

  sortDirectionValueChanged(newValue, oldValue) {
    const sortTarget = this.sortTargets.find((target) => this.#sortNameFromTarget(target) === this.sortByValue);

    if (oldValue) {
      if (!sortTarget) {
        for (const target of this.sortTargets) {
          target.classList.remove(...this[`sort${capitalizeFirstLetter(oldValue)}Classes`]);
        }

        return;
      }

      sortTarget.classList.remove(...this[`sort${capitalizeFirstLetter(oldValue)}Classes`]);
    }

    if (newValue) {
      sortTarget?.classList?.add(...this[`sort${capitalizeFirstLetter(newValue)}Classes`]);
    }
  }

  get #sortStateKey() {
    return `${this.identifier}--sortState`;
  }

  #loadSortState() {
    const sortState = JSON.parse(window.localStorage.getItem(this.#sortStateKey));
    if (!sortState) return;

    this.sortByValue = sortState.by;
    this.sortDirectionValue = sortState.direction;
  }

  #persistSortState() {
    window.localStorage.setItem(
      this.#sortStateKey,
      JSON.stringify({ by: this.sortByValue, direction: this.sortDirectionValue }),
    );
  }

  #updateSortFromTarget(target) {
    const oldSortBy = this.sortByValue;
    const newSortBy = this.#sortNameFromTarget(target);
    const firstSortDirection = this.#firstSortDirectionFromTarget(target);

    if (oldSortBy === newSortBy) {
      const nextDirection = nextSortDirection({
        current: this.sortDirectionValue,
        first: firstSortDirection,
      });

      if (nextDirection === '') {
        this.sortByValue = '';
        this.sortDirectionValue = '';
      } else {
        this.sortDirectionValue = nextDirection;
      }

      return;
    }

    this.sortByValue = newSortBy;
    this.sortDirectionValue = nextSortDirection({ current: '', first: firstSortDirection });
  }

  #sortNameFromTarget(target) {
    return target.dataset.sortName;
  }

  #firstSortDirectionFromTarget(target) {
    return target.dataset.firstSortDirection || 'asc';
  }

  /// Settings

  get #settingsData() {
    if (!this.settingsCookieNameValue) return null;

    return JSON.parse(
      window.localStorage.getItem(this.settingsCookieNameValue) || readCookie(this.settingsCookieNameValue),
    );
  }
}
