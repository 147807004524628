export default class OneOffMicrotaskDispatch {
  constructor() {
    this.queued = false;
  }

  call(callback) {
    if (this.queued) return;

    this.queued = true;
    queueMicrotask(() => {
      callback();
      this.queued = false;
    });
  }
}
